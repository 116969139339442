import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import axios from "axios"
// axios.defaults.withCredentials = false;
// axios.defaults.headers.common['Authorization'] = 'token'
// axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'



Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$http = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
});
Vue.use(ElementUI)
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
